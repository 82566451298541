import axios from "./http";

// 资讯列表
export const getConsultsList = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/account/consults",
    params,
  });
};

// 资讯详情
export const getConsultsdetails = (id) => {
  return axios({
    method: "get",
    url: `/api/v1/account/consults/${id}`,
  });
};
