<template>
  <div class="do-page-information do-com-safearea-tabbar">
    <div class="do-page-information-nav">
      <div
        class="nav-wrap"
        :style="{
          paddingTop: `calc(${
            $Hbuild.getStatusBarHeight() + $ComJs.bili * 0.4
          }px)`,
        }"
      >
        <div class="nav-item">资讯</div>
        <div class="nav-item-curr"></div>
      </div>
    </div>
    <base-bscroll
      class="do-page-information-scroll"
      @refScroll="refScroll"
      :style="{
        top: `calc(${$Hbuild.getStatusBarHeight() + $ComJs.bili * 1.08}px)`,
      }"
      :options="{
        bounce: false,
        firstChildrenHeight: true,
        useTransition: false,
        pullUpLoad: true,
      }"
    >
      <div class="do-page-information-scroll-list">
        <div class="scroll-head-bg"></div>
        <div v-if="skeletonShow">
          <div class="item-card" v-for="item in 6" :key="item">
            <div class="left-wrap">
              <div class="title">
                <van-skeleton class="information-title-skeleton" :row="3">
                </van-skeleton>
              </div>
              <div class="info">
                <!-- <span class="split">|</span>
                <span>来源：赢商网</span> -->
              </div>
            </div>
            <div class="information-title-skeleton-img">
              <van-loading type="spinner" size="20" />
            </div>
          </div>
        </div>

        <div v-for="item in listData" :key="item.id">
          <div
            v-if="item.type === 2"
            class="main-card"
            @click="$router.push('/information/details/pdf/' + item.id)"
          >
            <van-image
              :src="item.main_pic"
              width="100%"
              height="3.65rem"
              radius="0.12rem"
            >
            </van-image>
            <div class="title">{{ item.title }}</div>
            <div class="main-card-content">时间：{{ item.release_time }}</div>
          </div>
          <div
            class="item-card"
            @click="$router.push('/information/details/' + item.id)"
            v-if="item.type === 1"
          >
            <div class="left-wrap">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="info">
                <span>时间：{{ item.release_time }}</span>
                <!-- <span class="split">|</span>
                <span>来源：赢商网</span> -->
              </div>
            </div>
            <van-image
              :src="item.main_pic"
              width="2.12rem"
              height="1.46rem"
              radius="0.12rem"
            >
            </van-image>
          </div>
        </div>
        <div v-if="listData.length !== 0">
          <div v-if="pullingUpBol" class="information-loding-more">加载中</div>
          <div v-if="!pullingUpBol" class="information-loding-more">
            没有更多了
          </div>
        </div>
      </div>
    </base-bscroll>
  </div>
</template>

<script setup>
import { getConsultsList } from "@/axios/information";
import { ref, onActivated, onMounted, nextTick } from "vue";
import { Toast } from "vant";
const cursor = ref({});
const listData = ref([]);
const pullingUpBol = ref(true);
const isNodata = ref(false);
let scrollRef = null;
const skeletonShow = ref(true);

onActivated(() => {
  if (listData.value.length === 0) {
    setTimeout(() => {
      getDataList();
    }, 200);
  } else {
    // 切换搜索页再回来就滚动不了
    scrollRef.refresh();
  }
});

// onMounted(() => {
//   getDataList();
// });

function refScroll(scroll) {
  scrollRef = scroll;
  scrollRef.on("pullingUp", () => {
    console.log("pullingUp");
    if (cursor.value) {
      setTimeout(async () => {
        await getDataList();
        scrollRef.finishPullUp();
      }, 500);
    }
  });
}

async function getDataList() {
  try {
    const { code, data } = await getConsultsList({
      cursor: cursor.value,
    });
    if (code === 0 && data) {
      skeletonShow.value = false;
      // 暂无数据
      if (
        data &&
        data.items &&
        data.items.length === 0 &&
        listData.value.length == 0
      ) {
        isNodata.value = true;
      } else {
        listData.value = listData.value.concat(data.items || []);
        cursor.value = data.next;
        if (!cursor.value) {
          pullingUpBol.value = false;
          console.log("pullingUpBol", pullingUpBol);
        }
        nextTick(() => {
          if (scrollRef) scrollRef.refresh();
        });
      }
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "服务端错误 ",
      });
    }
  }
}
</script>

<style lang="scss">
.do-page-information {
  overflow: hidden;
  background: #f5f8fc;
  background: #0057ff;
  .information-title-skeleton {
    width: 4rem;
    padding: 0;
  }
  .information-loding-more {
    text-align: center;
    color: #969799;
    padding-bottom: 0.24rem;
  }
  .do-com-scroll-content {
    padding-bottom: 0.02rem;
  }
  .van-image {
    overflow: unset !important;
  }
  &-nav {
    height: 2.84rem;
    padding-top: 1.08rem;
    padding-left: 0.24rem;
    position: relative;
    display: flex;
    .nav-wrap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      width: 100%;
      // padding-top: 1.05rem;
      background: #0057ff;
    }
    .nav-item {
      display: flex;
      justify-content: center;
      position: relative;
      font-size: 0.36rem;
      width: auto;
      font-weight: 500;
      color: #ffffff;
      width: 0.77rem;
      width: 100%;
      text-align: center;
      // height: 0.9rem;
      height: 0.7rem;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   width: 0.24rem;
      //   height: 0.08rem;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   bottom: 0;
      //   background: #ffffff;
      //   border-radius: 8px;
      // }
    }
    .nav-item-curr {
    }
  }

  .scroll-head-bg {
    background: #0057ff;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    height: 1.2rem;
  }
  &-scroll {
    background: #f5f8fc;
    width: 100%;
    position: fixed;
    bottom: calc($tabbar_hegiht + constant(safe-area-inset-bottom));
    bottom: calc($tabbar_hegiht + env(safe-area-inset-bottom));
    top: 1.74rem;
    left: 0;

    &-list {
      padding: 0.24rem 0.24rem 0 0.24rem;
      .main-card {
        border-radius: 0.16rem;
        overflow: hidden;
        background: #ffffff;
        margin-bottom: 0.24rem;
        padding: 0.16rem 0.16rem 0rem 0.16rem;
        img {
          width: 100%;
          height: 3.65rem;
          border-radius: 0.12rem;
        }
        .title {
          font-size: 0.32rem;
          font-weight: bold;
          color: #02040f;
          padding: 0 0.08rem;
          margin-top: 0.24rem;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .main-card-content {
          font-size: 0.24rem;
          margin-top: 0.08rem;
          font-weight: 400;
          color: rgba(2, 4, 15, 0.25);
          padding: 0 0.08rem;
          padding-bottom: 0.24rem;
        }
      }
      .item-card {
        font-size: 0.32rem;
        padding: 0.24rem;
        font-weight: 500;
        color: #02040f;
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        border-radius: 0.16rem;
        margin-bottom: 0.24rem;
        .left-wrap {
          display: flex;
          flex-flow: column;
          margin-right: 0.4rem;
          font-weight: bold;
          justify-content: space-between;
          width: calc(100% - 2.52rem);
        }
        .info {
          .split {
            margin: 0 0.16rem;
            color: rgba(2, 4, 15, 0.12);
          }
          span {
            font-size: 0.24rem;
            font-weight: 400;
            color: rgba(2, 4, 15, 0.25);
          }
        }
        img {
          width: 2.12rem;
          border-radius: 0.12rem;
          height: 1.46rem;
        }
        .information-title-skeleton-img {
          width: 2.12rem;
          border-radius: 0.12rem;
          height: 1.46rem;
          display: flex;
          background: #f7f8fa;
          justify-content: center;
          align-items: center;
        }
        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
</style>
